import  { useMemo } from "react";
import "./Pagination.css";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useLocation } from "react-router-dom";

const Pagination = ({
  actualPage,
  tasksCount,
  limit,
  setActualPage,
  setSearchParams,
  setOffset
}) => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const pageHandler = (action) => {
    if (action === "next" && actualPage < Math.ceil(tasksCount / limit)) {
      setActualPage(actualPage + 1);
      if (setSearchParams) {
        query.set("offset", actualPage * limit);
        query.set("page", actualPage + 1);
        setSearchParams(query);
      }
      setOffset(actualPage * limit);
    }

    if (action === "back" && actualPage > 1) {
      setActualPage(actualPage - 1);
      if (setSearchParams) {
        query.set("offset", (actualPage - 2) * limit);
        query.set("page", actualPage - 1);
        setSearchParams(query);
      }
      setOffset((actualPage - 2) * limit);
    }
  };
  return (
    <div>
      <nav>
        <div className="pagination__container">
          <div className="pagination__element">
            <AiOutlineArrowLeft
              title="Anterior"
              size={20}
              color={"#E88A00"}
              onClick={() => pageHandler("back")}
            />
          </div>
          <div>
            <p className="pagination__text">
              {actualPage}/{Math.ceil(tasksCount / limit)}
            </p>
          </div>

          <div className="pagination__element">
            <AiOutlineArrowRight
              title="Siguiente"
              size={20}
              color={"#E88A00"}
              onClick={() => pageHandler("next")}
            />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Pagination;
