import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import "./ModalDomainEdition.css";
import { useForm } from "react-hook-form";
import validationIBAN from "../../helpers/validationIBAN";
import { updateDomain } from "../../services/domain.service";
import { removeNullFields } from "../../helpers/removeNullFields";
import CustomAlert from "../Alert/CustomAlert";
import { isDatePastOrToday } from "../../utils/isDatePastOrToday";

const ModalDomainEdition = ({ show, handleClose, domain, type }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [errorUpload, setErrorUpload] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    reset({
      financiera: domain?.financiera || null,
      importe: domain?.importe || null,
      iban: domain?.iban || null,
      concepto:
        `${domain?.concepto.replace(
          `${domain?.concepto.split("-")[0]}-`,
          ""
        )}` || null,
      fechaLimite: domain?.fechaLimite || null,
      financieraFile: domain?.financieraFile|| null,
      informeTraficoFile: domain?.informeTraficoFile || null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const isDateAfterOrToday = (value) => {
    const date = new Date(value).getTime();
    const today = new Date().getTime();
    return date >= today;
  };

  const showAlertHandler = () => {
    setErrorUpload(false);
    setShowSuccess(false);
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      
    const existsInformeTraficoFile = data.informeTraficoFile && 
      data.informeTraficoFile.length > 0 && 
      Array.isArray([...data.informeTraficoFile])

    const existsFinancieraFile = data.financieraFile &&
      data.financieraFile.length > 0 &&
      Array.isArray([...data.financieraFile])
    
    const payload = {
      ...data,
      id: domain.id,
      concepto: `${domain.concepto.split("-")[0]}-${data.concepto}`,
      financieraFile: existsFinancieraFile ? data.financieraFile[0] : null,
      informeTraficoFile: existsInformeTraficoFile ? data.informeTraficoFile[0] : null,
        }    

    const payloadFilter = removeNullFields(payload)

    const response  = await updateDomain(payloadFilter);
    if (response) {
      handleClose();
      setShowSuccess(true);
      setLoading(false);
    }
      } catch (error) {
        handleClose();
      setLoading(false);
      setErrorUpload(true);
    }
      }
  

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="modal_header">
          <Modal.Title>{`Editar ${type}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {type === "financiera" && (
              <Row className="mt-4">
                <h6>Datos financiera:</h6>
                <Col className="my-1">
                  <input
                    className={`form-control ${
                      errors.financiera && "is-invalid"
                    } `}
                    placeholder="Nombre"
                    {...register("financiera", { required: true })}
                  />
                  {errors.financiera?.type === "required" && (
                    <span className="error">
                      Introduza el financiera de la financiera
                    </span>
                  )}
                </Col>
              </Row>
            )}

            {type === "importe" && (
              <Row className="mt-4">
                <h6 title="Introduza importe">Importe financiado:</h6>
                <Col className="my-1">
                  <input
                    type="number"
                    step="any"
                    className={`form-control ${
                      errors.importe && "is-invalid"
                    } `}
                    placeholder="Importe"
                    {...register("importe", { required: true })}
                  />
                  {errors.importe?.type === "required" && (
                    <span className="error">Introduzca importe</span>
                  )}
                </Col>
              </Row>
            )}

            {type === "iban" && (
              <Row>
                <Col className="my-1">
                  <input
                    className={`form-control ${errors.iban && "is-invalid"} `}
                    placeholder="IBAN"
                    {...register("iban", {
                      required: true,
                      validate: (value) => validationIBAN(value),
                    })}
                  />
                  {errors.iban?.type === "required" && (
                    <span className="error">Introduzca número de IBAN</span>
                  )}
                  {errors.iban && errors.iban?.type !== "required" && (
                    <span className="error">
                      El IBAN introducido es incorrecto (ESXXYYYYYYYYZZZZZZZZZZZZ)
                    </span>
                  )}
                </Col>
              </Row>
            )}

            {type === "concepto" && (
              <Row>
                <Col xs={2} className="custom__margin">
                  <span>{`${domain.concepto.split("-")[0]}-`}</span>
                </Col>
                <Col className="my-1">
                  <input
                    className={`form-control ${
                      errors.concepto && "is-invalid"
                    } `}
                    placeholder="Concepto especificado en el pago"
                    {...register("concepto", { required: true })}
                  />
                  {errors.concepto?.type === "required" && (
                    <span className="error">Introduza el concepto</span>
                  )}
                </Col>
              </Row>
            )}

            {type === "fecha" && (
              <Row>
                <Col>
                  <input
                    type="date"
                    className={`form-control ${
                      errors.fechaLimite && "is-invalid"
                    } `}
                    placeholder="Fecha límite"
                    {...register("fechaLimite", {
                      pattern: /^(19[7-9]\d|20\d{2})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                      validate:isDateAfterOrToday,
                      required: true,
                    })}
                  />
                  {errors.fechaLimite?.type === "required" && (
                    <span className="error">
                      Introduzca fecha límite para realizar el pago.
                    </span>
                  )}         
                 {errors.fechaLimite && errors.fechaLimite?.type !== "required" && (
                    <span className="error">Introduzca Fecha del contrato Válida</span>
                  )}
                </Col>
              </Row>
            )}
              {type === "financieraFile" && (
                    <Row className="my-3">
                      <Col>
                        <h6>
                          Financera File:{" "}
                        </h6>
                        <input
                          accept="application/pdf"
                          type="file"
                          className={`form-control`}
                          {...register("financieraFile")}
                        />
                      </Col>
                    </Row>
                  )}
             {type === "informeTraficoFile" && (
                    <Row className="my-3">
                      <Col>
                        <h6>
                          Informe de Trafico:{" "}
                        </h6>
                        <input
                          accept="application/pdf"
                          type="file"
                          className={`form-control`}
                          {...register("informeTraficoFile")}
                        />
                      </Col>
                    </Row>
                  )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn_custom" onClick={handleSubmit(onSubmit)}>
            Actualizar
          </Button>
        </Modal.Footer>
      </Modal>
    </form>
    {errorUpload && (
        <CustomAlert
          tittle="Error al subir documentación"
          message="Ha habido un problema al adjuntar la documentación. Vuelva a intentarlo más tarde y en caso de que el error persista contacte con su administrador."
          variant="danger"
          showAlertHandler={showAlertHandler}
        />
      )}
      {showSuccess && (
        <CustomAlert
          tittle="Documentación"
          message="El archivo ha sido adjuntada con éxito."
          variant="success"
          showAlertHandler={showAlertHandler}
        />
      )}
    </>
  );
};

export default ModalDomainEdition;
