import { useState } from "react";
import { Container, Row, Table } from "react-bootstrap";
import { useUserContext } from "contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { GoAlert } from "react-icons/go";
import { sendSageRecords } from "services/taskService";
import { errorToast, successToast } from "helpers/toastFunction";

const PendingTasksTable = ({ tasks, getTasksHandler }) => {
  const { user } = useUserContext();
  const navigate = useNavigate();

  const [loadingTaskId, setLoadingTaskId] = useState(null);

  const societyHandler = (societyId) => {
    if (user.companiesOptions) {
      const society = user.companiesOptions.find(
        (item) => item.value === societyId
      );
      return society?.label;
    }
  };

  const handleSendSageRecords = async (taskId) => {
    setLoadingTaskId(taskId);
    try {
      const response = await sendSageRecords(taskId);
      if (response.status === 204) {
        successToast("Gestión subida a Sage con éxito");
      }
      getTasksHandler();
    } catch (error) {
      errorToast("Error en la Subida a Sage");
    } finally {
      setLoadingTaskId(null);
    }
  };

  return (
    <Container fluid className="px-4">
      <Row>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Identificador</th>
              <th>Sociedad</th>
              <th>Origen</th>
              <th>Matrícula</th>
              <th>Ir a gestión</th>
              <th>Subir a Sage</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((task) => (
              <tr key={task.id}>
                <td>{task.id}</td>
                <td>{societyHandler(task.society)}</td>
                <td>{task.originLabel}</td>
                <td>{task.matricula}</td>
                <td>
                  <span
                    onClick={() => navigate(`/gestion/${task.id}`)}
                    style={{
                      color: "#fe9800",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                  >
                    Gestión
                  </span>
                </td>
                <td>
                  <Button
                    className="btn_custom"
                    onClick={() => handleSendSageRecords(task.id)}
                    disabled={loadingTaskId === task.id}
                  >
                    {loadingTaskId === task.id ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Subir a Sage"
                    )}
                  </Button>
                </td>
                <td>
                  <GoAlert size={26} color={"red"} title={task.sage.message} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
};

export default PendingTasksTable;
