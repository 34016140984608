import React from "react";
import { Route, Routes } from "react-router-dom";

//views
import ConfirmPaymentDomain from "../../Views/Domains/ConfirmPaymentDomain/ConfirmPaymentDomain";
import Domains from "../../Views/Domains/Domains";
import Historic from "../../Views/Historic/Historic";
import Home from "../../Views/Home/Home";
import GoogleLogin from "../../Views/GoogleLogin/GoogleLogin";
import ConfirmPay from "../../Views/Payment/ConfirmPay/ConfirmPay";
import Payment from "../../Views/Payment/Payment";
import Profile from "../../Views/Profile/Profile";
import TaskDetail from "../../Views/TaskDetail/TaskDetail";
import ProviderList from "../../Views/ProviderList/ProviderList";
import AddInvoice from "../../Views/AddInvoice";
import Invoices from "../../Views/Invoices/Invoices";
import HistoricoList from "../../Views/Historico/HistoricoList";
import ContractsList from "../../Views/ContractsList/ContractsList";
import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";
import PendingTasks from "Views/PendingTasks/PendingTasksLisk";

const AppRouter = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>Ruta no encontrada 404!</p>
          </main>
        }
      />
      <Route path="login" element={<GoogleLogin />} />
      <Route path="" element={<ProtectedRoute component={<Home />} />} />
      <Route path="/home" element={<ProtectedRoute component={<Home />} />} />
      <Route
        path="gestion/:id"
        element={<ProtectedRoute component={<TaskDetail />} />}
      />
      <Route
        path="gestiones/pagar"
        element={<ProtectedRoute component={<Payment />} />}
      />
      <Route
        path="gestiones/confirmar"
        element={<ProtectedRoute component={<ConfirmPay />} />}
      />
      <Route
        path="reserva_dominio/pendientes"
        element={<ProtectedRoute component={<Domains />} />}
      />
      <Route
        path="reserva_dominio/confirmar"
        element={<ConfirmPaymentDomain />}
      />
      <Route
        path="perfil"
        element={<ProtectedRoute component={<Profile />} />}
      />
      <Route
        path="historial"
        element={<ProtectedRoute component={<Historic />} />}
      />
      <Route
        path="nueva-factura"
        element={<ProtectedRoute component={<AddInvoice />} />}
      />
      <Route
        path="provider"
        element={<ProtectedRoute component={<ProviderList />} />}
      />
      <Route
        path="facturas"
        element={<ProtectedRoute component={<Invoices />} />}
      />
      <Route
        path="historico"
        element={<ProtectedRoute component={<HistoricoList />} />}
      />
      <Route
        path="contratos"
        element={<ProtectedRoute component={<ContractsList />} />}
      />
      <Route
        path="gestiones_pendientes"
        element={<ProtectedRoute component={<PendingTasks />} />}
      />
    </Routes>
  );
};

export default React.memo(AppRouter);
