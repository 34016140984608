import React, { useEffect, useState, useCallback } from "react";
import { Spinner, Col, Container, Row, Form } from "react-bootstrap";
import Pagination from "../../components/Pagination/Pagination";
import PendingTasksTable from "./components/PendingTasksTable";
import { getTasks } from "../../services/axiosCall";
import { useUserContext } from "contexts/UserContext";
import useDebounce from "hooks/useDebounce";
import Select from "react-select";

import "./PendingTasksList.css";

const PendingTasks = () => {
  const { user } = useUserContext();

  const [tasks, setTasks] = useState(null);
  const [actualPage, setActualPage] = useState(1);
  const [limit] = useState(25);
  const [tasksCount, setTasksCount] = useState(null);
  const [offset, setOffset] = useState(0);
  const [societyFilter, setSocietyFilter] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");

  const debouncedChangedFilter = useDebounce(searchFilter, 500);

  const getTasksHandler = useCallback(async () => {
    if (user.dealerships.length > 0) {
      const response = await getTasks({
        userId: user.id,
        limit: limit,
        offset: offset,
        society: societyFilter?.map((society) => society?.value),
        search: debouncedChangedFilter, 
        errorSageRecords: true,
      });
      setTasks(response.data);
      setTasksCount(response.count);
    }
  }, [user.dealerships, user.id, offset, limit, societyFilter, debouncedChangedFilter]);

  const filterHandler = (data) => {
    if (actualPage > 1) {
      setOffset(0);
      setActualPage(1);
    }
    if (data.type === "society" && data.item.length !== 0) {
      setSocietyFilter(data.item);
    } else if (data.type === "society" && data.item.length === 0) {
      setSocietyFilter(undefined);
    }
  };

  const handleSearchChange = (e) => {
    setSearchFilter(e.target.value);
    setOffset(0);
    setActualPage(1);
  };

  useEffect(() => {
    getTasksHandler();
  }, [getTasksHandler]);

  return (
    <Container fluid className="px-4">
      <Row className="mt-4-custom">
        <Col className="col-12">
          <div className={`clearfix mt-3 mb- containerTitle`}>
            <span className="tittle ml-4">Gestiones Pendientes</span>
          </div>
        </Col>
      </Row>
      <Row className="mt-4-custom">
        <Col className="col-3">
          <Select
            placeholder="Sociedad"
            onChange={(item) => filterHandler({ item, type: "society" })}
            value={societyFilter}
            options={user.companiesOptions}
            isMulti
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#fe9800",
                primary25: "#ffc548",
              },
            })}
          />
        </Col>
        <Col className="col-3">
          <Form.Control
            type="text"
            placeholder="Buscar matricula"
            value={searchFilter}
            onChange={handleSearchChange}
          />
        </Col>
      </Row>
      <Row className="mt-3-custom">
        {tasks ? (
          tasks.length > 0 ? (
            <PendingTasksTable tasks={tasks} getTasksHandler={getTasksHandler} />
          ) : (
            <div className="center">
              <h4 className="custom__space">No existen gestiones.</h4>
            </div>
          )
        ) : (
          <div className="center">
            <h4 className="custom__space">
              <Spinner animation="border" variant="warning" />
            </h4>
          </div>
        )}
        {tasks && tasks.length > 0 && (
          <Pagination
            actualPage={actualPage}
            tasksCount={tasksCount}
            limit={limit}
            setOffset={setOffset}
            setActualPage={setActualPage}
          />
        )}
      </Row>
    </Container>
  );
};

export default PendingTasks;