import { removeTokens } from "../../../store/AccessTokenStore";
import RolType from "../../../constants/roles";

export const menuItems = [
  {
    title: "Gestiones Pendientes",
    url: "/gestiones_pendientes",
    rol: [RolType.GENERAL_ADMIN, RolType.CENTRAL_ADMIN],
  },
  {
    title: "Contratos",
    url: "/contratos",
    rol: [RolType.GENERAL_ADMIN, RolType.CENTRAL_ADMIN, RolType.ADMINISTRATION],
  },
  {
    title: "Facturas Vehículos",
    url: "#",
    rol: [RolType.GENERAL_ADMIN, RolType.CENTRAL_ADMIN],
    submenu: [
      {
        title: "Histórico",
        url: "/historial",
      },
      {
        title: "Pagos",
        submenu: [
          {
            title: "Pendientes",
            url: "/gestiones/pagar",
          },
          {
            title: "Confirmar Firma",
            url: "/gestiones/confirmar",
          },
        ],
      },
      {
        title: "Reservas Dominio",
        submenu: [
          {
            title: "Pendientes",
            url: "/reserva_dominio/pendientes",
          },
          {
            title: "Confirmar Firma",
            url: "/reserva_dominio/confirmar",
          },
        ],
      },
      {
        title: "Gestiones",
        url: "/home",
      },
    ],
  },
  {
    title: "Facturas Vehículos",
    url: "#",
    rol: Object.values(RolType).filter(
      (item) =>
        item !== RolType.GENERAL_ADMIN &&
        item !== RolType.CENTRAL_ADMIN &&
        item !== RolType.CENTRAL_APPRAISER
    ),
    submenu: [
      {
        title: "Histórico",
        url: "/historial",
      },
      {
        title: "Gestiones",
        url: "/home",
      },
    ],
  },
  {
    title: "Facturas Vehículos",
    url: "#",
    rol: [RolType.CENTRAL_APPRAISER],
    submenu: [
      {
        title: "Histórico",
        url: "/historial",
      },
      {
        title: "Gestiones",
        url: "/home",
      },
    ],
  },
  {
    title: "",
    url: "#",
    rol: Object.values(RolType),
    submenu: [
      {
        title: "Mi Perfil",
        url: "/perfil",
      },
      {
        title: "Cerrar Sesión",
        url: "/login",
        action: () => {
          removeTokens();
        },
      },
    ],
  },
];

const rolePriority = [
  RolType.GENERAL_ADMIN,
  RolType.CENTRAL_ADMIN,
  RolType.CENTRAL_APPRAISER,
  ...Object.values(RolType).filter(
    (item) =>
      item !== RolType.GENERAL_ADMIN &&
      item !== RolType.CENTRAL_ADMIN &&
      item !== RolType.CENTRAL_APPRAISER
  ),
];

const getMenu = (userRoles) => {
  const highestPriorityRole = rolePriority.find((role) => userRoles.includes(role));
  const menuFilter = menuItems.filter((item) =>
    item.rol.includes(highestPriorityRole)
  );
  return menuFilter;
};

export default getMenu;
