
import { FiAlertTriangle, FiCheckCircle } from "react-icons/fi";
import "./SageNotice.css";
import { formatDateToLocalString } from "utils/formatDateToLocalString";
import { SageStatus } from "utils/constants/constants";

export const SageNotice = ({ sage }) => {
  if (!sage) return null;

  return (
    <div
      className={`sage-notice ${sage.status === SageStatus.SENT ? "sage-notice--success" : "sage-notice--error"}`}
    >
      <h6 className="sage-notice__message">
        {sage.status === SageStatus.SENT ? (
          <>
            <FiCheckCircle size={20} className="sage-notice__icon sage-notice__icon--success" />
            Subida a Sage Correctamente el {formatDateToLocalString(sage.sentAt)}
          </>
        ) : (
          <>
            <FiAlertTriangle size={20} className="sage-notice__icon sage-notice__icon--alert" />
            No se ha podido enviar a Sage a fecha {formatDateToLocalString(sage.sentAt)}
          </>
        )}
      </h6>
    </div>
  );
};
