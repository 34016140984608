import { config } from "../config/environment.config";
import { axiosInstance, instance } from "./BaseService";

export const post = async (route, data) => {
  try {
    const response = await instance.post(route, {
      headers: {
        "Content-Type": "application/json",
      },
      ...data,
    });

    return response.data;
  } catch (e) {}
};





export const createTask = async (formData) => {
  try {
    return axiosInstance.post(
      `${config.API_HOST}/task`,
      formData
    );
  } catch (error) {}
};

export const getTasks = async (data) => {
  try {
    return axiosInstance.get(`${config.API_HOST}/task`, {
      params: data,
    });
  } catch (error) {}
};

export const getTask = async (id) => {
  try {
    return axiosInstance.get(`${config.API_HOST}/task/${id}`);
  } catch (error) {}
};

export const updateTask = async (data) => {
  try {
    return axiosInstance.patch(`${config.API_HOST}/task`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {}
};

export const updatePayment = async (data) => {
  try {
    return axiosInstance.patch(
      `${config.API_HOST}/task/payment`,
      data,
      {}
    );
  } catch (error) {}
};

export const updateTaskPayment = async (data) => {
  try {
    return axiosInstance.patch(
      `${config.API_HOST}/task/payment`,
      data,
      {}
    );
  } catch (error) {}
};

export const deleteTask = async (taskId) => {
  try {
    return axiosInstance.delete(
      `${config.API_HOST}/task/${taskId}`
    );
  } catch (error) {}
};


export const getSignedUrl = async (fileId) => {
  try {
    return axiosInstance.get(
      `${config.API_HOST}/storage/${fileId}`
    );
  } catch (error) {}
};

export const getVehicleOrigin = async () => {
  try {
    return axiosInstance.get(
      `${config.API_HOST}/options/origin`
    );
  } catch (error) {}
};

export const getTaxesTypes = async () => {
  try {
    return axiosInstance.get(`${config.API_HOST}/options/taxes`);
  } catch (error) {}
};

export const getProcedureTypes = async () => {
  try {
    return axiosInstance.get(
      `${config.API_HOST}/options/procedures`
    );
  } catch (error) {}
};

export const getManagementTypes = async () => {
  try {
    return axiosInstance.get(
      `${config.API_HOST}/options/management`
    );
  } catch (error) {}
};
