import { TiEdit } from "react-icons/ti";

export const EditButton = ({ checkVisibility, onClick }) => {
  if (!checkVisibility) return null;

  return (
    <TiEdit
      size={30}
      color="#E88A00"
      className="cursor_pointer mx-2"
      onClick={onClick}
      aria-label="Edit"
      role="button"
    />
  );
};