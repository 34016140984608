import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Form, Row, Spinner, Button } from "react-bootstrap";
import { useLocation, useSearchParams } from "react-router-dom";
import Select from "react-select";

import Pagination from "../../components/Pagination/Pagination";
import TableCollapsible from "./Components/TableCollapsible/TableCollapsible";
import "./Home.css";
import {
  getManagementTypes,
  getProcedureTypes,
  getTasks,
} from "../../services/axiosCall";
import RolType from "../../constants/roles";
import PaymentState from "../../constants/paymentState";

import {
  DOMAIN_OPTIONS,
  INCIDENCE_OPTIONS,
  ORIGIN_OPTIONS,
  PAYMENT_OPTIONS,
  STATE_OPTIONS,
} from "../../constants/filterOptions";

import { numberPrettier } from "../../utils/constants/numberPrettier";
import useDebounce from "../../hooks/useDebounce";
import { useUserContext } from "contexts/UserContext";

const Home = () => {
  const { user, socket } = useUserContext();

  const [tasks, setTasks] = useState(null);
  const [totalDebt, setTotalDebt] = useState(null);
  const [totalDomainDebt, setTotalDomainDebt] = useState(null);
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const [actualPage, setActualPage] = useState(parseFloat(query.get("page")) || 1);
  const [limit] = useState(25);
  const [tasksCount, setTasksCount] = useState(null)
  const [gestionFilter, setGestionFilter] = useState(null);
  const [searchFilter, setSearchFilter] = useState(undefined);
  const [localFilter, setLocalFilter] = useState(null);
  const [societyFilter, setSocietyFilter] = useState(null);
  const [tramiteFilter, setTramiteFilter] = useState(null);
  const [estadoFilter, setEstadoFilter] = useState(null);
  const [pagoFilter, setPagoFilter] = useState(null);
  const [originFilter, setOriginFilter] = useState(null);
  const [domainFilter, setDomainFilter] = useState(null);
  const [incidenceFilter, setIncidenceFilter] = useState(null);
  const [fetchedLocals, setFetchedLocals] = useState(false);
  const [filterChanged, setFilterChanged] = useState(false);
  const [fetchedInitialData, setFetchedInitialData] = useState(false);
  
  const [procedureTypes, setProcedureTypes] = useState([]);
  const [managementTypes, setManagementTypes] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const initialOffset = parseFloat(searchParams.get("offset")) || 0
  const [offset, setOffset] = useState(initialOffset);  




  const debouncedChangedFilter  = useDebounce(filterChanged, 500);

  const getTasksHandler = useCallback(async () => {
    if (user.dealerships.length > 0) {
      const response = await getTasks(
        {
          userId: user.id,
          limit: limit,
          completed: false,
          offset: offset,
          locals: localFilter?.map((local) => local?.value),
          society: societyFilter?.map((society) => society?.value),
          gestion: gestionFilter?.map((gestionFilter) => gestionFilter?.value),
          tramite: tramiteFilter?.map((tramite) => tramite?.value),
          state: estadoFilter?.map((estado) => estado?.value),
          pay: pagoFilter?.map((pago) => pago?.value),
          origin: originFilter?.map((origin) => origin?.value),
          domain: domainFilter?.map((domain) => domain?.value),
          incidence: incidenceFilter?.map((incidence) => incidence?.value),
          search: searchFilter,
        }
      );
      const unfinished = response.data;
      if (domainFilter) {
        let arrayByDate = unfinished.sort(function (a, b) {
          return (
            new Date(a.domain?.fechaLimite) - new Date(b.domain?.fechaLimite)
          );
        });
        setTasks(arrayByDate);
      }
      setTasksCount(response.count)
      setTasks(unfinished);
      setTotalDebt(response.amountToBePaid);
      setTotalDomainDebt(response.domainAmountToBePaid);
    }
  }, [user, limit, offset, localFilter, societyFilter, gestionFilter, tramiteFilter, estadoFilter, pagoFilter, originFilter, domainFilter, incidenceFilter, searchFilter]);

  const showTotalAmount = () => {
    let show = false;
    if (pagoFilter) {
      pagoFilter.forEach((item) => {
        if (
          item.value === PaymentState.UNPROCESSED ||
          item.value === PaymentState.VALIDATED
        ) {
          show = true;
        }
      });
    }
    return show;
  };

  const showTotalDomainAmount = () => {
    let show = false;
    if (domainFilter) {
      domainFilter.forEach((item) => {
        if (item.value === PaymentState.VALIDATED) {
          show = true;
        }
      });
    }
    return show;
  };

  const getInitialFilterData = useCallback(
    (managementTypes, procedureTypes) => {
      const initialFilter = Object.fromEntries([...searchParams]);

      if (initialFilter?.pago) {
        const payments = JSON.parse(initialFilter.pago).map((payment) =>
          PAYMENT_OPTIONS.find((option) => option.value === payment)
        );
        setPagoFilter(payments);
      }

      if (initialFilter?.origin) {
        const origins = JSON.parse(initialFilter.origin).map((origin) =>
          ORIGIN_OPTIONS.find((option) => option.value === origin)
        );
        setOriginFilter(origins);
      }

      if (initialFilter?.gestion) {
        const managements = JSON.parse(initialFilter?.gestion).map(
          (management) =>
            managementTypes.find((type) => type.value === management)
        );
        setGestionFilter(managements);
      }

      if (initialFilter?.society) {
        const societies = JSON.parse(initialFilter.society).map((society) =>
          user.companiesOptions.find((company) => company.value === society)
        );
        setSocietyFilter(societies);
      }

      if (initialFilter?.local) {
        const sites = JSON.parse(initialFilter.local).map((site) =>
          user.dealershipOptions.find((local) => local.value === site)
        );
        setLocalFilter(sites);
      }

      if (initialFilter?.estado) {
        const states = JSON.parse(initialFilter.estado).map((state) =>
          STATE_OPTIONS.find((stateOption) => stateOption.value === state)
        );
        setEstadoFilter(states);
      }

      if (initialFilter?.incidence) {
        const incidents = JSON.parse(initialFilter.incidence).map((incidence) =>
          INCIDENCE_OPTIONS.find(
            (incidenceOption) => incidenceOption.value === incidence
          )
        );
        setIncidenceFilter(incidents);
      }

      if (initialFilter?.domain) {
        const domains = JSON.parse(initialFilter.domain).map((domain) =>
          DOMAIN_OPTIONS.find((domainOption) => domainOption.value === domain)
        );
        setDomainFilter(domains);
      }

      if (initialFilter?.tramite) {
        const procedures = JSON.parse(initialFilter.tramite).map((procedure) =>
          procedureTypes.find(
            (procedUreType) => procedUreType.value === procedure
          )
        );
        setTramiteFilter(procedures);
      }

      if (initialFilter?.search) {
        setSearchFilter(initialFilter.search);
      }

      setFilterChanged(true);
    },
    [searchParams, user.companiesOptions, user.dealershipOptions]
  );

  const getInitialData = useCallback(async () => {
    try {
      const procedureTypesResponse = await getProcedureTypes();
      setProcedureTypes(procedureTypesResponse);

      const managementTypesResponse = await getManagementTypes();
      setManagementTypes(managementTypesResponse);

      setFetchedInitialData(true);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("update", getTasksHandler);
      return () => {
        socket.off("update");
      };
    }
  }, [getTasksHandler, socket]);

  const filterHandler = (data) => {
    const value = data?.item?.map((item) => item.value);
    if (actualPage > 1){
      setOffset(0)
      setActualPage(1)
      searchParams.delete("offset")
      searchParams.delete("page")
    };
    if (data.type === "gestion" && data.item.length !== 0) {
      searchParams.set("gestion", JSON.stringify(value));
      setGestionFilter(data.item);
    } else if (data.type === "gestion" && data.item.length === 0) {
      setGestionFilter(undefined);
      searchParams.has("gestion") && searchParams.delete("gestion");
    }

    if (data.type === "society" && data.item.length !== 0) {
      searchParams.set("society", JSON.stringify(value));
      setSocietyFilter(data.item);
    } else if (data.type === "society" && data.item.length === 0) {
      setSocietyFilter(undefined);
      searchParams.has("society") && searchParams.delete("society");
    }

    if (data.type === "local" && data.item.length !== 0) {
      searchParams.set("local", JSON.stringify(value));
      setLocalFilter(data.item);
    } else if (data.type === "local" && data.item.length === 0) {
      setLocalFilter(undefined);
      searchParams.has("local") && searchParams.delete("local");
    }

    if (data.type === "tramite" && data.item.length !== 0) {
      searchParams.set("tramite", JSON.stringify(value));
      setTramiteFilter(data.item);
    } else if (data.type === "tramite" && data.item.length === 0) {
      setTramiteFilter(undefined);
      searchParams.has("tramite") && searchParams.delete("tramite");
    }

    if (data.type === "estado" && data.item.length !== 0) {
      searchParams.set("estado", JSON.stringify(value));
      setEstadoFilter(data.item);
    } else if (data.type === "estado" && data.item.length === 0) {
      setEstadoFilter(undefined);
      searchParams.has("estado") && searchParams.delete("estado");
    }

    if (data.type === "pago" && data.item.length !== 0) {
      searchParams.set("pago", JSON.stringify(value));
      setPagoFilter(data.item);
    } else if (data.type === "pago" && data.item.length === 0) {
      setPagoFilter(undefined);
      searchParams.has("pago") && searchParams.delete("pago");
    }

    if (data.type === "origin" && data.item.length !== 0) {
      searchParams.set("origin", JSON.stringify(value));
      setOriginFilter(data.item);
    } else if (data.type === "origin" && data.item.length === 0) {
      setOriginFilter(undefined);
      searchParams.has("origin") && searchParams.delete("origin");
    }

    if (data.type === "domain" && data.item.length !== 0) {
      searchParams.set("domain", JSON.stringify(value));
      setDomainFilter(data.item);
    } else if (data.type === "domain" && data.item.length === 0) {
      setDomainFilter(undefined);
      searchParams.has("domain") && searchParams.delete("domain");
    }

    if (data.type === "incidence" && data.item.length !== 0) {
      searchParams.set("incidence", JSON.stringify(value));
      setIncidenceFilter(data.item);
    } else if (data.type === "incidence" && data.item.length === 0) {
      setIncidenceFilter(undefined);
      searchParams.has("incidence") && searchParams.delete("incidence");
    }

    if (
      data.type === "search" &&
      (data.e.target.value.length > 0)
    ) {
      searchParams.set("search", data.e.target.value);
      setSearchFilter(data.e.target.value);
    } else if (data.type === "search" && data.e.target.value.length === 0) {
      setSearchFilter(undefined);
      searchParams.has("search") && searchParams.delete("search");
    }
    setSearchParams(searchParams);
    setFilterChanged(true);
  };


  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  useEffect(() => {
    fetchedLocals &&
      fetchedInitialData &&
      managementTypes.length > 0 &&
      procedureTypes.length > 0 &&
      getInitialFilterData(managementTypes, procedureTypes);
  }, [
    fetchedLocals,
    fetchedInitialData,
    managementTypes,
    procedureTypes,
    getInitialFilterData,
  ]);

  useEffect(() => {
    if (user.dealerships.length > 0 && !fetchedLocals) {
      setFetchedLocals(true);
    }
  }, [user, fetchedLocals]);

  useEffect(() => {
    if (debouncedChangedFilter) {
      getTasksHandler();
      setFilterChanged(false);
    }
  }, [debouncedChangedFilter, getTasksHandler]);

  const clearFilter = () => {
    setGestionFilter([]);
    setTramiteFilter([]);
    setSocietyFilter([]);
    setLocalFilter([]);
    setEstadoFilter([]);
    setIncidenceFilter([]);
    setDomainFilter([]);
    setPagoFilter([]);
    setOriginFilter([]);
    setSearchFilter("");
    setSearchParams(undefined);
    setFilterChanged(true);
    setOffset(0)
    setActualPage(1)
  };

  return (
    <Container fluid>
      <Row>
        <Col className="col-12">
          <div className={`clearfix mt-3 mb- containerTitle`}>
            <span className="tittle ml-4">Gestiones</span>
          </div>
        </Col>
      </Row>
      <Row className="mb-3 mt-3 custom__relative">
        <Col>
          <Select
            placeholder="Tipo de gestión"
            onChange={(item) => filterHandler({ item, type: "gestion" })}
            value={gestionFilter}
            options={managementTypes}
            isMulti
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#fe9800",
                primary25: "#ffc548",
              },
            })}
          />
        </Col>
        <Col>
          <Select
            placeholder="Tipo de trámite"
            onChange={(item) => filterHandler({ item, type: "tramite" })}
            value={tramiteFilter}
            options={procedureTypes}
            isMulti
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#fe9800",
                primary25: "#ffc548",
              },
            })}
          />
        </Col>
        <Col>
          <Select
            placeholder="Sociedad"
            onChange={(item) => filterHandler({ item, type: "society" })}
            value={societyFilter}
            options={user.companiesOptions}
            isMulti
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#fe9800",
                primary25: "#ffc548",
              },
            })}
          />
        </Col>
        {user.roles.includes(RolType.CENTRAL_ADMIN) ||
          (user.roles.includes(RolType.GENERAL_ADMIN) && (
            <Col>
              <Select
                placeholder="Local"
                onChange={(item) => filterHandler({ item, type: "local" })}
                value={localFilter}
                options={user.dealershipOptions}
                isMulti
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "#fe9800",
                    primary25: "#ffc548",
                  },
                })}
              />
            </Col>
          ))}
        <Col>
          <Select
            placeholder="Estado gestión"
            onChange={(item) => filterHandler({ item, type: "estado" })}
            options={STATE_OPTIONS}
            value={estadoFilter}
            isMulti
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#fe9800",
                primary25: "#ffc548",
              },
            })}
          />
        </Col>
        <Col>
          <Select
            placeholder="Incidencias"
            onChange={(item) => filterHandler({ item, type: "incidence" })}
            options={INCIDENCE_OPTIONS}
            value={incidenceFilter}
            isMulti
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#fe9800",
                primary25: "#ffc548",
              },
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={2}>
          <Select
            placeholder="Reserva de Dominio"
            onChange={(item) => filterHandler({ item, type: "domain" })}
            options={DOMAIN_OPTIONS}
            value={domainFilter}
            isMulti
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#fe9800",
                primary25: "#ffc548",
              },
            })}
          />
        </Col>
        <Col xl={2}>
          <Select
            placeholder="Estado de pago"
            onChange={(item) => filterHandler({ item, type: "pago" })}
            value={pagoFilter}
            options={PAYMENT_OPTIONS}
            isMulti
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#fe9800",
                primary25: "#ffc548",
              },
            })}
          />
        </Col>
        <Col xl={2}>
          <Select
            placeholder="Tipo de Cliente"
            onChange={(item) => filterHandler({ item, type: "origin" })}
            value={originFilter}
            options={ORIGIN_OPTIONS}
            isMulti
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#fe9800",
                primary25: "#ffc548",
              },
            })}
          />
        </Col>
        <Col xl={2}>
          <Form>
            <Form.Group className="mb-3">
              <Form.Control
                value={searchFilter}
                onChange={(e) => filterHandler({ e, type: "search" })}
                placeholder="Matrícula, DNI, CIF, NIE"
              />
            </Form.Group>
          </Form>
        </Col>

        <Col xl={2}>
          {Object.keys(Object.fromEntries([...searchParams])).length > 0 && (
            <Button variant="secondary" onClick={() => clearFilter()}>
              Borrar filtros
            </Button>
          )}
        </Col>
      </Row>

      <div className="amount-summary">
        {totalDebt > 0 && showTotalAmount() && (
          <div className="amount-summary__items">
            <h6 className="mt-2">
              Deuda total:<span> {numberPrettier(totalDebt)}€</span>
            </h6>
          </div>
        )}

        {totalDomainDebt > 0 && showTotalDomainAmount() && (
          <div className="amount-summary__items">
            <h6 className="mt-2">
              R. Dominio:
              <span> {numberPrettier(totalDomainDebt)}€</span>
            </h6>
          </div>
        )}
        <div className="amount-summary__single-item">
          <h6 className="mt-2">
            Nº de coches:<span> {tasksCount}</span>
          </h6>
        </div>
      </div>

      {tasks ? (
        tasks.length > 0 ? (
          <TableCollapsible
            tasks={tasks}
            locals={user.dealershipOptions}
          />
        ) : (
          <div className="center">
            <h4 className="custom__space">No existen gestiones.</h4>
          </div>
        )
      ) : (
        <div className="center">
          <h4 className="custom__space">
            <Spinner animation="border" variant="warning" />
          </h4>
        </div>
      )}

      {tasks && tasks.length > 0 && (
        <Pagination
          actualPage={actualPage}
          tasksCount={tasksCount}
          limit={limit}
          setOffset={setOffset}
          setActualPage={setActualPage}
          setSearchParams={setSearchParams}
        />
      )}

    </Container>
  );
};

export default Home;
