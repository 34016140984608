const DocumentationAttachmentType = {
    CAMBIO_TITULARIDAD: 1,   //gestoría
    CONTRATO: 2,
    DNI: 3,
    FICHA_TECNICA: 4,
    PERMISO_CIRCULACION: 5,
    CIF: 6,
    IAE: 7,
    ESCRITURA: 8,
    FACTURA_PROFORMA: 9,
    FACTURA_DEFINITIVA: 10,
    CERTIFICADO_TITULARIDAD: 11,
    INFORME_TRAFICO: 12,
  };
  export default DocumentationAttachmentType;
  