import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { updateTask } from "../../services/axiosCall";
import CustomAlert from "../Alert/CustomAlert";
import "../ModalDocumentation/ModalDocumentation.css";

const ModalDocumentation = ({
  show,
  handleClose,
  existingTask,
  type,
  setFileState = undefined,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    reset({
      circulacionFile: null,
      contractFile: null,
      certificadoBancarioFile: null,
      dniFile: null,
      fichaTecnicaFile: null,
      gestoriaFile: null,
      cifFile: null,
      escrituraFile: null,
      facturaVentaFile: null,
      informeTraficoFile: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const [errorUpload, setErrorUpload] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const showAlertHandler = () => {
    setErrorUpload(false);
    setShowSuccess(false);
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      if(type !== 'Factura general') {
        const formData = {
          id: existingTask.id,
          documentationId: existingTask.files?.id,
          cifFile: data.cifFile ? data.cifFile[0] : null,
          dniFile: data.dniFile ? data.dniFile[0] : null,
          contractFile: data.contractFile ? data.contractFile[0] : null,
          certificadoBancarioFile: data.certificadoBancarioFile ? data.certificadoBancarioFile[0] : null,
          informeTraficoFile: data.informeTraficoFile ? data.informeTraficoFile[0] : null,
          circulacionFile: data.circulacionFile ? data.circulacionFile[0] : null,
          fichaTecnicaFile: data.fichaTecnicaFile
            ? data.fichaTecnicaFile[0]
            : null,
          facturaVentaFile: data.facturaVentaFile
            ? data.facturaVentaFile[0]
            : null,
          numeroFactura: data.numeroFactura ? data.numeroFactura : null,
          fechaFactura: data.fechaFactura ? data.fechaFactura : null,
          escrituraFile: data.escrituraFile ? data.escrituraFile[0] : null,
          gestoriaFile: data.gestoriaFile ? data.gestoriaFile[0] : null,
          proformaFile: data.proformaFile ? data.proformaFile[0] : null,
          iaeFile: data.iaeFile ? data.iaeFile[0] : null,
        };

        const dataFiltered = Object.entries(formData).reduce(
          (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
          {}
        );
        const response = await updateTask(dataFiltered);
        if (response) {
          handleClose();
          setShowSuccess(true);
          setLoading(false);
        }
      } else {
        setFileState && setFileState(data.invoiceFile?.[0]);
        handleClose();
      }

    } catch (error) {
      handleClose();
      setLoading(false);
      setErrorUpload(true);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <Modal show={show} onHide={handleClose}>
          <Modal.Header className="modal_header">
            <Modal.Title>Adjuntar {type}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col xs={12} md={12} lg={12} xl={12}>
                  {type === "Contrato" && (
                    <Row className="my-3">
                      <Col>
                        <h6>
                          Contrato:{" "}
                          {existingTask?.contractFile && "(actualizar)"}{" "}
                        </h6>
                        <input
                          accept="application/pdf"
                          type="file"
                          className={`form-control`}
                          {...register("contractFile")}
                        />
                      </Col>
                    </Row>
                  )}
                    {type === "Certificado Bancario Titularidad" && (
                    <Row className="my-3">
                      <Col>
                        <h6>
                        Certificado Bancario Titularidad:{" "}
                          {existingTask?.certificadoBancarioFile && "(actualizar)"}{" "}
                        </h6>
                        <input
                          accept="application/pdf"
                          type="file"
                          className={`form-control`}
                          {...register("certificadoBancarioFile")}
                        />
                      </Col>  
                    </Row>
                  )}
                  {
                    type === "Informe de Tráfico" && (
                      <Row className="my-3">
                        <Col>
                          <h6>
                            Informe de Tráfico:{" "}
                            {existingTask?.informeTraficoFile && "(actualizar)"}{" "}
                          </h6>
                          <input
                            accept="application/pdf"
                            type="file"
                            className={`form-control`}
                            {...register("informeTraficoFile")}
                          />
                        </Col>
                      </Row>
                    )
                  }
                  {type === "Dni" && (
                    <Row className="my-3">
                      <Col>
                        <h6>DNI: {existingTask?.dniFile && "(actualizar)"} </h6>
                        <input
                          accept="application/pdf"
                          type="file"
                          className={`form-control`}
                          {...register("dniFile")}
                        />
                      </Col>
                    </Row>
                  )}
                  {type === "Ficha Técnica" && (
                    <Row className="my-3">
                      <Col>
                        <h6>
                          Ficha técnica:{" "}
                          {existingTask?.fichaTecnicaFile && "(actualizar)"}{" "}
                        </h6>
                        <input
                          accept="application/pdf"
                          type="file"
                          className={`form-control`}
                          {...register("fichaTecnicaFile")}
                        />
                      </Col>
                    </Row>
                  )}
                  {type === "Permiso de Circulación" && (
                    <Row className="my-3">
                      <Col>
                        <h6>
                          Permiso de circulación:{" "}
                          {existingTask?.circulacionFile && "(actualizar)"}{" "}
                        </h6>
                        <input
                          accept="application/pdf"
                          type="file"
                          className={`form-control`}
                          {...register("circulacionFile")}
                        />
                      </Col>
                    </Row>
                  )}

                  {type === "Cif" && (
                    <Row className="my-3">
                      <Col>
                        <h6>CIF: {existingTask?.cifFile && "(actualizar)"} </h6>
                        <input
                          accept="application/pdf"
                          type="file"
                          className={`form-control`}
                          {...register("cifFile")}
                        />
                      </Col>
                    </Row>
                  )}
                  {type === "Escritura" && (
                    <Row className="my-3">
                      <Col>
                        <h6>
                          Escritura:{" "}
                          {existingTask?.escrituraFile && "(actualizar)"}{" "}
                        </h6>
                        <input
                          accept="application/pdf"
                          type="file"
                          className={`form-control`}
                          {...register("escrituraFile")}
                        />
                      </Col>
                    </Row>
                  )}

                  {type === "Factura de Venta" && (
                    <>
                      <Row className="my-3">
                        <Col>
                          <h6>
                            Factura:{" "}
                            {existingTask?.facturaVentaFile && "(actualizar)"}{" "}
                          </h6>
                          <input
                            accept="application/pdf"
                            type="file"
                            className={`form-control`}
                            {...register("facturaVentaFile")}
                          />
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col>
                          <h6>Nº factura</h6>
                          <input
                            className={`form-control ${
                              errors.numeroFactura && "is-invalid"
                            } `}
                            placeholder="Nº factura"
                            {...register("numeroFactura", { required: true })}
                          />
                          {errors.numeroFactura?.type === "required" && (
                            <span className="error">
                              Introduzca el nº de factura
                            </span>
                          )}
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col>
                          <h6>Fecha factura</h6>
                          <input
                            type="date"
                            className={`form-control ${
                              errors.fechaFactura && "is-invalid"
                            } `}
                            placeholder="Fecha del Factura"
                            {...register("fechaFactura", {
                              required: true,
                            })}
                          />
                          {errors.fechaFactura?.type === "required" && (
                            <span className="error">
                              Introduzca la fecha de la Factura.
                            </span>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}

                  {type === "Factura Proforma" && (
                    <Row className="my-3">
                      <Col>
                        <h6>
                          Factura Proforma:{" "}
                          {existingTask?.proformaFile && "(actualizar)"}{" "}
                        </h6>
                        <input
                          accept="application/pdf"
                          type="file"
                          className={`form-control`}
                          {...register("proformaFile")}
                        />
                      </Col>
                    </Row>
                  )}

                  {type === "Certificado IAE" && (
                    <Row className="my-3">
                      <Col>
                        <h6>
                          Certificado IAE:{" "}
                          {existingTask?.iaeFile && "(actualizar)"}{" "}
                        </h6>
                        <input
                          accept="application/pdf"
                          type="file"
                          className={`form-control`}
                          {...register("iaeFile")}
                        />
                      </Col>
                    </Row>
                  )}

                  {type === "Justificante de Gestoría" && (
                    <Row className="my-3">
                      <Col>
                        <h6>
                          Justificante gestoria:{" "}
                          {existingTask?.gestoriaFile && "(actualizar)"}{" "}
                        </h6>
                        <input
                          accept="application/pdf"
                          type="file"
                          className={`form-control`}
                          {...register("gestoriaFile")}
                        />
                      </Col>
                    </Row>
                  )}
                  {type === "Factura general" && (
                    <Row className="my-3">
                      <Col>
                        <h6>
                          Factura:
                        </h6>
                        <input
                          accept="application/pdf"
                          type="file"
                          className={`form-control`}
                          {...register("invoiceFile", {
                            required: true,
                            validate: (files) =>
                              files.length === 1 &&
                              files[0].type === "application/pdf",
                          })}
                        />
                        {(errors.invoiceFile?.type === "required" ||
                          errors.invoiceFile?.type === "validate") && (
                          <span className="error">
                            Añada un fichero de tipo pdf
                          </span>
                        )}
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            {!loading ? (
              <Button className="btn_custom" onClick={handleSubmit(onSubmit)}>
                Guardar
              </Button>
            ) : (
              <Button className="btn_custom" disabled>
                Subiendo...
                <Spinner
                  variant="warning"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </form>
      {errorUpload && (
        <CustomAlert
          tittle="Error al subir documentación"
          message="Ha habido un problema al adjuntar la documentación. Vuelva a intentarlo más tarde y en caso de que el error persista contacte con su administrador."
          variant="danger"
          showAlertHandler={showAlertHandler}
        />
      )}
      {showSuccess && (
        <CustomAlert
          tittle="Documentación"
          message="El archivo ha sido adjuntada con éxito."
          variant="success"
          showAlertHandler={showAlertHandler}
        />
      )}
    </div>
  );
};

export default ModalDocumentation;
