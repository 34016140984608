import React, { useState } from "react";
import { Button, Container, Modal, Row, Spinner } from "react-bootstrap";
import "./ModalManualExport.css";
import CustomAlert from "../Alert/CustomAlert";
import { sendSageRecords } from "services/taskService";
import { errorToast } from "helpers/toastFunction";

const ModalIncidence = ({ show, handleClose, task }) => {

  const [errorUpload, setErrorUpload] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showAlertHandler = () => {
    setErrorUpload(false);
    setShowSuccess(false);
  };

  const onSubmitManualExport = async () => {
    setIsLoading(true);
    try {
      const result = await sendSageRecords(task.id, { exported: true });
      if (result.status === 204) {
        setShowSuccess(true);
      }
    } catch (error) {
      errorToast("Error al realizar la exportanción manual")
      setErrorUpload(true);
    }
    finally {
      handleClose();
      setIsLoading(false);
    }
  };
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="modal_header">
          <Modal.Title>Información</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <h6>
                Este vehículo no cumple con los requisitos marcados para
                exportación de datos.
              </h6>
            </Row>

            <Row>
              <label>
                ¿Está seguro que quiere exportarlo? La acción es irreversible.
              </label>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn_custom" onClick={() => onSubmitManualExport()} disabled={isLoading}>
            Aceptar {isLoading &&
              <Spinner
                variant="warning"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />}
          </Button>
          <Button className="btn_custom" onClick={() => handleClose()}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
      {errorUpload && (
        <CustomAlert
          tittle="Error"
          message="Ha habido un problema a la hora de exportar manualmente. Vuelva a intentarlo más tarde y en caso de que el error persista contacte con su administrador."
          variant="danger"
          showAlertHandler={showAlertHandler}
        />
      )}

      {showSuccess && (
        <CustomAlert
          tittle="Edición"
          message="La exportación manual se ha completado correctamente."
          variant="success"
          showAlertHandler={showAlertHandler}
        />
      )}
    </div>
  );
};

export default ModalIncidence;
